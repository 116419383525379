<i18n src="@/locales/dental_crown.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="dental-crown">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <p>
          {{ $t('article_p1') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="section">
      <ImanArticle>
        <b-message
          type="is-info"
        >
          <p>
            {{ $t('message_1') }}
          </p>
        </b-message>
      </ImanArticle>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          <span v-html="$t('article_p2_1')" />
        </p>
        <p>
          {{ $t('article_p2_2') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[2]">
        <ImanH3>{{ $t('h3_title_2') }}</ImanH3>
        <p>
          {{ $t('article_p3') }}
        </p>
        <p>
          {{ $t('article_p4') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            {{ $t('collapse_p1') }}
          </p>
          <table class="table">
            <thead>
              <tr>
                <th />
                <th v-html="$t('th_1')" />
                <th v-html="$t('th_2')" />
                <th v-html="$t('th_3')" />
                <th v-html="$t('th_4')" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <th v-html="$t('th_5')" />
                <td>1000€</td>
                <td>250€</td>
                <td>250€</td>
                <td>200€</td>
              </tr>
              <tr>
                <th v-html="$t('th_6')" />
                <td>1200€</td>
                <td>300€</td>
                <td>295€</td>
                <td>295€</td>
              </tr>
              <tr>
                <th v-html="$t('th_7')" />
                <td>1800€</td>
                <td>350€</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <p>
            {{ $t('collapse_p2') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <p>
            {{ $t('collapse_p3') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_1')">
            {{ $t('more_info_1') }}
          </ImanMoreInfo>
          <div v-html="$t('collapse_p4')" />
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_3')"
        >
          <p>
            {{ $t('collapse_p5') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_4')"
        >
          <p>
            {{ $t('collapse_p6') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_5')"
        >
          <p>
            {{ $t('collapse_p7') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_2')">
            {{ $t('more_info_2') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_6')"
        >
          <p>
            {{ $t('collapse_p8') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_7')"
        >
          <p>
            {{ $t('collapse_p9') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_3')">
            {{ $t('more_info_3') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'DentalCrown',
    components: {
      ImanMoreInfo,
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        articlesCardContentId: [83, 611, 52, 46],
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'dental-care/crown/couronne-dentaire-definition-c-est-quoi.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/crown/preparation-dent-couronne-dentaire.jpg',
            position: 'right'
          },
          {
            path: 'dental-care/crown/pose-couronne-dentaire.jpg',
            position: 'right'
          }
        ]
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_3.1.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../assets/img/dental-care/h1-soins-dentaires-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../assets/img/dental-care/h1-soins-dentaires-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../assets/img/dental-care/h1-soins-dentaires-m.jpg");
    }
  }
</style>
